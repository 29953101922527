<template>
    <div>

        <van-button @click="showBox(1)">弹窗1</van-button>
        <van-button @click="showBox(2)">弹窗2</van-button>
        <van-button @click="showBox(3)">弹窗3</van-button>
        <van-button @click="showBox(4)">弹窗4</van-button>
        <van-button @click="showBox(5)">弹窗5</van-button>
        <van-button @click="showBox(6)">弹窗6</van-button>
        <van-button @click="showBox(7)">弹窗7</van-button>
        <van-button @click="showBox(8)">弹窗8</van-button>
        <van-button @click="showBox(9)">弹窗9</van-button>
        <van-button @click="showBox(10)">弹窗10</van-button>

        <model ref="model1">
            <div class="model_content">
                <span class="line1_txt">邀请用户首次登录成功</span>
                <span class="small_txt" style="margin-bottom: 0;"></span>
                <span class="yanz_txt">+50严值</span>
                <div class="btn"><span class="text">分享给好友</span></div>
                <div class="tip_box">
                    <span class="tip_txt">*新用户通过你分享的链接首次登录完成即算邀请成功；同时对方首次登录成功也能获取50严值</span>
                </div>
                <div class="border_top_txt">
                    <span class="run_time">赶时间？</span>
                </div>
                <div class="btn_group" style="margin-top: 0.2rem;">
                    <div class="button">直接充值</div>
                    <div class="button">加入会员</div>
                </div>
            </div>
        </model>

        <model ref="model2">
            <div class="model_content">
                <span class="line1_txt">啊噢，还不是超级VIP会员</span>
                <span class="small_txt">加入VIP，超10000份严审方案免费解锁</span>
                <div class="btn"><span class="text">去加入</span></div>
            </div>
        </model>

        <model ref="model3">
            <div class="model_content">
                <span class="line1_txt">今日解锁剩余份数：1</span>
                <div class="rpxBorder"></div>
                <div class="email">
                    <span class="email_txt">接收邮箱</span>
                    <i class="img iconfont icon-edit"></i>
                </div>
                <span class="e_mail">hello@xplanner.com.cn</span>
                <div class="btn"><span class="text">确认发送至邮箱</span></div>
                <div class="tip_box">
                    <div class="tip_txt">*确认发送后，今日剩余份数将为0</div>
                    <div class="tip_light">
                      <img class="inifinite-unlock-icon" src="https://cdn.001ppt.cn/assets/img/member/vip-icon02.svg">
                      <router-link to="/member" class="tip_light">
                        我要无限解锁 >
                      </router-link>
                    </div>
                </div>
            </div>
        </model>

        <model ref="model4">
            <div class="model_content">
                <div class="vip">
                    <img class="img" src="https://cdn.001ppt.cn/assets/img/member/vip-icon02.svg">
                    <span class="txt">会员无限</span>
                </div>
                <span class="line1_txt">无限收入囊中</span>
                <div class="rpxBorder"></div>
                <div class="email">
                    <span class="email_txt">接收邮箱</span>
                    <i class="img iconfont icon-edit"></i>
                </div>
                <span class="e_mail">hello@xplanner.com.cn</span>
                <div class="btn"><span class="text">确认发送至邮箱</span></div>
            </div>
        </model>

        <model ref="model5">
            <div class="model_content">
                <span class="line1_txt">确认使用严值：100</span>
                <div class="count">
                    <span class="count_num">严值剩余：615</span>
                    <span class="count_option">去充值 ></span>
                </div>
                <div class="rpxBorder"></div>
                <div class="email">
                    <span class="email_txt">绑定接收邮箱</span>
                </div>
                <div class="email_input_box">
                    <input class="email_input">
                </div>
                <div class="yanz_code">
                    <input type="number" class="input_code" placeholder="请输入邮箱验证码">
                    <div class="tap_get_code"><span>获取验证码</span></div>
                </div>

                <div class="btn"><span class="text">确认发送至邮箱</span></div>
                <div class="tip_box">
                    <span class="tip_txt">*发送成功后，严值将自动抵扣，请注意查收邮件噢</span>
                </div>
            </div>
        </model>

        <model ref="model6">
            <div class="model_content">
                <span class="line1_txt">确认使用严值：100</span>
                <div class="count">
                    <span class="count_num">严值剩余：615</span>
                    <span class="count_option">去充值 ></span>
                </div>
                <div class="rpxBorder"></div>
                <div class="email">
                    <span class="email_txt">接收邮箱</span>
                    <i class="img iconfont icon-edit"></i>
                </div>
                <span class="e_mail">hello@xplanner.com.cn</span>
                <div class="btn"><span class="text">确认发送至邮箱</span></div>
                <div class="tip_box">
                    <span class="tip_txt">*发送成功后，严值将自动抵扣，请注意查收邮件噢</span>
                </div>
            </div>
        </model>

        <model ref="model7">
            <div class="model_content">
                <div class="vip">
                    <img class="img" src="https://cdn.001ppt.cn/assets/img/member/vip-icon02.svg">
                    <span class="txt">会员8折</span>
                </div>
                <span class="line1_txt">确认使用严值：80</span>
                <div class="count">
                    <span class="count_num">严值剩余：615</span>
                    <span class="count_option">去充值 ></span>
                </div>
                <div class="rpxBorder"></div>
                <div class="email">
                    <span class="email_txt">接收邮箱</span>
                    <i class="img iconfont icon-edit"></i>
                </div>
                <span class="e_mail">hello@xplanner.com.cn</span>
                <div class="btn"><span class="text">确认发送至邮箱</span></div>
                <div class="tip_box">
                    <span class="tip_txt">*发送成功后，严值将自动抵扣，请注意查收邮件噢</span>
                </div>
            </div>
        </model>

        <model ref="model8" :showClose="false">
            <div class="model_content">
                <div class="daojishi"><span class="txt">2秒</span></div>
                <img class="gou" src="https://cdn.001ppt.cn/h5/assets/images/gou.png">
                <div class="email">
                    <span class="email_txt">已发送成功</span>
                </div>
                <span class="e_mail" style="margin-bottom: 0;">hello@xplanner.com.cn</span>
            </div>
        </model>


        <model ref="model9" :showClose="false">
            <div class="model_content">
                <div class="daojishi"><span class="txt">2秒</span></div>
                <img class="gou" src="https://cdn.001ppt.cn/h5/assets/images/gou.png">
                <div class="email">
                    <span class="email_txt" style="font-weight: bold;">会员兑换成功！</span>
                </div>
            </div>
        </model>

        <model ref="model10" :showClose="false">
            <div class="model_content">
                <span class="line1_txt">今日解锁剩余份数：0</span>
                <div class="rpxBorder"></div>
                <span class="small_txt">加入VIP，超10000份严审方案免费解锁</span>
                <div class="btn"><span class="text">去加入</span></div>
            </div>
        </model>
    </div>
</template>

<script>
    import model from '@/components/model/model'
    export default {
        components: {model},
        name: 'modelAll',
        data(){

        },
        methods: {
            showBox(index){
                this.$refs['model'+index].toggleDialog();
            }
        }
    }
</script>

<style scoped lang="less">
    /* components/msgModel/msgModel.wxss */

    // .model_content{
    //     display: flex;
    //     flex-direction: column;
    //     align-items: center;
    // }
    // .model_content .line1_txt{
    //     font-size: 40/200rem;
    //     font-weight: bold;
    //     line-height: 150%;
    // }
    // .model_content .line1_small_txt{
    //     font-size: 24/200rem;
    //     line-height: 150%;
    // }
    // .model_content .small_txt{
    //     font-size: 24/200rem;
    //     line-height: 150%;
    //     margin-top: 41/200rem;
    //     margin-bottom: 40/200rem;
    // }
    // .model_content .yanz_txt{
    //     font-size: 40/200rem;
    //     line-height: 150%;
    //     font-weight: bold;
    //     margin-bottom: 40/200rem;
    // }
    // .model_content .btn{
    //     background-color: #151d36;
    //     padding: 20/200rem 0;
    //     display: flex;
    //     width: 100%;
    //     border-radius: 47/200rem;
    //     justify-content: center;
    //     margin-bottom: 22/200rem;
    // }
    // .model_content .btn:last-child{
    //     margin-bottom: 0;
    // }
    // .model_content .btn .text{
    //     color: #fff;
    //     font-size: 32/200rem;
    // }
    // .model_content .btn:active{
    //     opacity: 0.7;
    // }

    // /* 提示灰色小字 */
    // .model_content .tip_txt{
    //     font-size: 24/200rem;
    //     line-height: 150%;
    //     color: #cdcfd5;
    // }

    // .model_content .btn_group{
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     margin-top: 83/200rem;
    //     margin-bottom: 20/200rem;
    // }

    // .model_content .btn_group .button{
    //     width: 160/200rem;
    //     height: 50/200rem;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     margin: 0 34/200rem;
    //     border: 1px solid #151d36;
    //     border-radius: 25/200rem;
    //     font-size: 26/200rem;
    // }


    // .model_content .count{
    //     position: relative;
    // }
    // .model_content .count_num{
    //     font-size: 40/200rem;
    //     line-height: 150%;
    //     font-weight: bold;
    // }
    // .model_content .count_option{
    //     font-size: 24/200rem;
    //     color: #cdcfd5;
    //     position: absolute;
    //     top: 15/200rem;
    //     right: -170/200rem;
    // }

    // .model_content .email{
    //     display: flex;
    //     justify-content: center;
    //     margin-top: 60/200rem;
    //     margin-bottom: 15/200rem;
    //     position: relative;
    // }
    // .model_content .email_txt{
    //     font-size: 25/200rem;
    //     line-height: 150%;
    // }
    // .model_content .email .img{
    //     width: 26/200rem;
    //     height: 26/200rem;
    //     position: absolute;
    //     top: 7/200rem;
    //     right: -40/200rem;
    // }
    // .model_content .e_mail{
    //     font-size: 24/200rem;
    //     line-height: 150%;
    //     font-weight: bold;
    //     margin-bottom: 40/200rem;
    // }

    // /* 邮箱输入框 */
    // .model_content .email_input_box{
    //     width: calc(100% - 0.45rem - 0.1rem);
    //     background-color: #f3f3f5;
    //     height: 65/200rem;
    //     padding: 0 20/200rem;
    //     border-radius: 9/200rem;
    //     margin-bottom: 20/200rem;
    // }
    // .model_content .email_input{
    //     width: 100%;
    //     height: 100%;
    //     text-align: center;
    //     border: none;
    //     outline: none;
    //     background-color: #f3f3f5;
    // }
    // .model_content .yanz_code{
    //     background-color: #f3f3f5;
    //     border-radius: 9/200rem;
    //     padding: 15/200rem;
    //     color: #cdcfd5;
    //     display: flex;
    //     align-items: center;
    //     margin-bottom: 50/200rem;
    // }
    // .model_content .yanz_code .input_code{
    //     flex: 1;
    //     font-size: 24/200rem;
    //     border: none;
    //     outline: none;
    //     background-color: #f3f3f5;
    // }
    // .model_content .yanz_code .tap_get_code{
    //     font-size: 24/200rem;
    //     padding: 0 30/200rem;
    //     border-left: 1px solid #999999;
    // }

    // /* 会员 */
    // .model_content .vip{
    //     border-radius: 20/200rem;
    //     background-color: #e1494e;
    //     position: absolute;
    //     top: -34/200rem;
    //     left: 20/200rem;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     padding: 7/200rem 10/200rem;
    //     padding-right: 20/200rem;
    // }
    // .model_content .vip .txt{
    //     font-size: 20/200rem;
    //     color: #fff;
    //     margin-left: 10/200rem;
    // }
    // .model_content .vip .img{
    //     width: 30/200rem;
    //     height: 30/200rem;
    // }

    // .model_content .daojishi{
    //     position: absolute;
    //     top: -30/200rem;
    //     right: 40/200rem;
    // }
    // .model_content .daojishi .txt{
    //     font-size: 24/200rem;
    //     line-height: 150%;
    //     color: #cdcfd5;
    // }

    // .model_content .gou{
    //     width: 200/200rem;
    //     height: 200/200rem;
    // }
</style>
